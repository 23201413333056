<template>
  <v-app>
    <v-container fluid class="background">
      <v-app-bar color="transparent" dark elevation="0" height="78">
        <v-avatar class="mr-2">
          <img
            v-if="siteInfo.logo"
            :src="bascImg + siteInfo.logo"
            alt="Company Logo"
          />
        </v-avatar>
        <v-toolbar-title class="display-2 font-weight-bold">
          A9 ERP
          {{ $vuetify.lang.t("$vuetify.guanlixitong") }}
        </v-toolbar-title>

        <v-spacer />

        <v-menu
          offset-y
          bottom
          left
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="ml-2"
                  min-width="0"
                  text
                  v-bind="{ ...attrsMenu, attrsTooltip }"
                  v-on="{ ...onTooltip, ...onMenu }"
                >
                  <v-icon>mdi-translate</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.changeLang") }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="setCurrentLang('en')">
              <v-list-item-title>English</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setCurrentLang('zhHans')">
              <v-list-item-title>中文</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setCurrentLang('kh')">
              <v-list-item-title>ភាសាខ្មែរ</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-row style="height: calc(100vh - 78px)">
        <v-col cols="12" class="d-flex justify-center align-center">
          <div class="myDiv">
            <v-card elevation="0" dark color="transparent">
              <v-card-title class="d-flex justify-center py-2">
                <div class="font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.login") }}
                </div>
              </v-card-title>

              <v-form v-model="valid" ref="form" autoComplete="on">
                <v-card-text class="pb-0">
                  <v-text-field
                    prepend-inner-icon="mdi-account"
                    name="login"
                    :label="$vuetify.lang.t('$vuetify.userName')"
                    id="login"
                    type="text"
                    filled
                    v-model="form.username"
                    :rules="[rules.required]"
                    @keypress.enter="submitLogin"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="form.password"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    required
                    :type="show1 ? 'text' : 'password'"
                    name="password"
                    :label="$vuetify.lang.t('$vuetify.password')"
                    filled
                    @click:append="show1 = !show1"
                    @keypress.enter="submitLogin"
                  ></v-text-field>
                </v-card-text>
              </v-form>

              <v-card-actions class="pt-2 pr-4 d-flex justify-end">
                <v-btn color="green darken-1" @click="submitLogin">
                  {{ $vuetify.lang.t("$vuetify.login") }}
                </v-btn>
              </v-card-actions>

              <v-card-title
                class="d-flex justify-center align-end"
                style="height: 40px"
              >
                <div>&copy; {{ date }} - A9 Online</div>
              </v-card-title>
            </v-card>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-app>
</template>

<script>
import serverConfig from "@/utils/serverConfig";
import { setUser } from "@/utils/auth";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 4),
      valid: false,
      bascImg: serverConfig.api_url + "/file/",
      form: {
        username: "",
        password: ""
      },
      dialog: true,
      show1: false,
      rules: {
        required: value => !!value || this.$vuetify.lang.t("$vuetify.required")
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "siteInfo"])
  },
  methods: {
    setCurrentLang(lang) {
      this.$vuetify.lang.current = lang;
      localStorage.setItem("lang", lang);
    },
    submitLogin() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("Login", this.form)
          .then(res => {
            if (res.meta === 0) {
              setUser(this.form);
              this.$router.push({ path: "/" });
            }
          })
          .catch(res => {
            console.log(res);
            this.$toast.error(this.$vuetify.lang.t("$vuetify.loginFailed"))
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setCurrentLang(lang) {
      this.$vuetify.lang.current = lang;
      localStorage.setItem("lang", lang);
      location.reload();
    }
  }
};
</script>

<style>
.myDiv {
  width: 400px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 1);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.myDiv:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 200px rgba(255, 255, 255, 1);
  filter: blur(100px);
  margin: -20px;
}

.background {
  background-image: url("https://picsum.photos/1920/1080");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.6);
}

</style>
